import { VueReCaptcha } from 'vue-recaptcha-v3'
export const useInitRecaptcha = () => {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: config.public.RECAPTCHA_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
}
