<script lang="ts" setup>
import { useReCaptcha } from 'vue-recaptcha-v3'
import { useAuth } from '~/store/auth'

interface Props {
  errors?: string
  withConfirmation?: boolean
  initCaptcha?: boolean
  trackCaptcha?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withConfirmation: false,
  initCaptcha: false,
  trackCaptcha: false,
})
const emit = defineEmits(['goBack', 'changeEmail', 'otpComplete'])
const { t } = useI18n()
const authStore = useAuth()
const isCaptchaError = ref()
const isLoading = ref(false)
const isOTPSuccess = ref(false)
const form = reactive({ code: null })
const errorVal = ref()
const errorList = [
  { id: 'ERR_NOT_FOUND', value: 'Invalid code' },
  { id: 'ERR_EXPIRED', value: 'This code expired' },
  { id: 'ERR_INVALID_UNKNOWN', value: t('auth_error_server_error') },
  { id: 'ERR_MAX_REQUEST_EXCEEDED', value: 'You are exceeding daily login attempt' },
  { id: 'ERR_WITHIN_COOLDOWN_PERIOD', value: 'Please wait a few minutes and try again' },
  { id: 'ERR_PHONE_OR_EMAIL_UNAVAILABLE', value: t('auth_error_no_account') },
]
const modalText = computed(() => [
  { id: 0, icon: 'material-symbols:mark-email-read-outline', title: 'Confirm your email', subtitle: 'You will need this email in for login to Epicareer later. <br> Does this email correct?', otpDigitCount: 0, timer: 30 },
  { id: 1, icon: 'mdi:email-arrow-right-outline', title: 'Please check your email', subtitle: `We have sent a 6-digit code to : ${authStore?.otpPayload?.recipient}<br> Check your spam folder as well.`, otpDigitCount: 6, timer: 30 },
  { id: 2, icon: 'logos:whatsapp-icon', title: 'Verify number', subtitle: `To complete the changes, please verify your number. <br> We will send a code to ${authStore?.otpPayload?.recipient} via WhatsApp`, otpDigitCount: 0, timer: 30 },
  { id: 3, icon: 'logos:whatsapp-icon', title: 'Check your Whatsapp', subtitle: `Please enter the code we sent to : <br>${authStore?.otpPayload?.recipient}`, otpDigitCount: 4, timer: 30 },
])
const selectedText = ref(setSelectedText())

watch(() => props.errors, (newVal) => {
  if (newVal)
    isLoading.value = false
  errorVal.value = errorList.find(item => item.id === newVal)?.value || newVal
})

onMounted(async () => {
  // init captcha only if needed, to prevent error duplicated
  if (props.initCaptcha) {
    useInitRecaptcha()
    const recaptchaInstance = useReCaptcha()
    await recaptchaInstance?.recaptchaLoaded()
    const captchaToken = await recaptchaInstance?.executeRecaptcha('login')
    isCaptchaError.value = await authStore.checkCaptchaValid(captchaToken, props.trackCaptcha)
  }
})

function setSelectedText(idx?: number) {
  if (idx)
    return modalText.value[idx]
  else if (props.withConfirmation && authStore?.otpPayload?.channel === 'email')
    return modalText.value[0]
  else if (props.withConfirmation && authStore?.otpPayload?.channel === 'whatsapp')
    return modalText.value[2]
  else if (!props.withConfirmation && authStore?.otpPayload?.channel === 'email')
    return modalText.value[1]
  else if (!props.withConfirmation && authStore?.otpPayload?.channel === 'whatsapp')
    return modalText.value[3]
}

async function sendOTP() {
  if ((props.initCaptcha && !isCaptchaError.value) || !props.initCaptcha) {
    isLoading.value = true
    errorVal.value = null
    const otpRes = await authStore.sendOTP(authStore?.otpPayload?.recipient, authStore?.otpPayload?.type, authStore?.otpPayload?.channel)
    if (otpRes.status) {
      isOTPSuccess.value = true
      if (selectedText.value?.id === 0)
        selectedText.value = setSelectedText(1)
      else if (selectedText.value?.id === 2)
        selectedText.value = setSelectedText(3)
    }
    else {
      errorVal.value = errorList.find(item => item.id === otpRes.msg)?.value || otpRes.msg
      isOTPSuccess.value = false
    }
    isLoading.value = false
  }
}

function updateOTP(otp: string) {
  form.code = otp
  if (otp.length === selectedText?.value?.otpDigitCount) {
    isLoading.value = true
    errorVal.value = null
    emit('otpComplete', otp)
  }
}
</script>

<template>
  <div class="text-gray-900 relative min-h-md">
    <!-- header -->
    <div class="space-y-4 mb-10">
      <Icon :name="selectedText?.icon" class="w-12 h-12 text-[#5843E5]" />
      <p class="text-2xl font-bold">
        {{ selectedText?.title }}
      </p>
      <div class="text-sm text-[#666666]" v-html="selectedText?.subtitle" />
    </div>

    <!-- otp box or email/whatsapp number -->
    <div>
      <div v-if="selectedText?.otpDigitCount > 0">
        <ContinueOTPBox :digit-count="selectedText?.otpDigitCount" :is-error="errorVal" @update:otp="updateOTP($event)" />
        <ContinueResendOTP class="mt-4" :recipient="authStore?.otpPayload?.recipient" :type="authStore?.otpPayload?.type" :channel="authStore?.otpPayload?.channel" :timeout="selectedText?.timer" />
      </div>
      <div v-if="selectedText?.id === 0" class="flex items-center">
        <span class="text-xs font-semibold">{{ authStore?.otpPayload?.recipient }}</span>
        <span class="text-sm text-indigo-500 ml-auto cursor-pointer" @click="emit('changeEmail')">Change email</span>
      </div>
    </div>

    <!-- action button -->
    <div class="absolute bottom-0 w-full">
      <div v-if="selectedText?.id === 0 || selectedText?.id === 2" class="grid">
        <BaseMoleculesInputError class="mb-2 mx-auto" :invalid-checker="!!errorVal" :error-val="errorVal" />
        <div class="flex items-center">
          <BaseAtomsButton size="md" outline classes="font-semibold" @click="emit('goBack')">
            Go back
          </BaseAtomsButton>
          <BaseAtomsButton v-if="isLoading" size="md" classes="px-6 font-semibold ml-auto">
            <div class="flex items-center">
              <Icon name="eos-icons:loading" class="h-5 w-5 text-white mx-auto mr-2" aria-hidden="true" />
              <span> Loading ...</span>
            </div>
          </BaseAtomsButton>
          <BaseAtomsButton v-else size="md" classes="font-semibold ml-auto" @click="sendOTP()">
            Continue
          </BaseAtomsButton>
        </div>
      </div>
      <div v-else class="flex">
        <BaseAtomsButton v-if="isLoading" size="md" classes="px-6 font-semibold w-full">
          <div class="flex items-center">
            <Icon name="eos-icons:loading" class="h-5 w-5 text-white mx-auto mr-2" aria-hidden="true" />
            <span> Loading ...</span>
          </div>
        </BaseAtomsButton>
        <BaseAtomsButton v-else size="md" outline classes="font-semibold w-full border-0 shadow-none" @click="emit('goBack')">
          Cancel
        </BaseAtomsButton>
      </div>
    </div>
  </div>
</template>
